import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'gatsby';
import { Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import pages from '../../resources/pages';
import { useTranslation } from 'react-i18next';
import { EMailIcon, PhoneIcon, FbMessengerIcon } from '../icons/Icons';
import { MainContainer } from '../misc/containers';
import CSSTransition from 'react-transition-group/CSSTransition';
import makeStyles from '@material-ui/core/styles/makeStyles';

const BackButton = withStyles({
  root: {
    background: 'linear-gradient(45deg, #f85047 30%, #FF8E53 90%)',
    '&:hover': {
      filter: 'brightness(85%)',
    },
    '&:focus': {
      filter: 'brightness(85%)',
    },
    borderRadius: 6,
    border: 0,
    color: 'white',
    height: '5rem',
    width: '20rem',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
    fontSize: '2rem',
    fontWeight: 'bold',
  },
})(Button);

const useStyles = makeStyles(() => ({
  label: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bolder',
  },
}));

export const goToMessenger = () => {
  window.open('https://m.me/107657807368147', '_blank');
};

const ContactBody = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <CSSTransition appear={true} in={true} classNames="fadein" timeout={1000}>
      <MainContainer>
        <Row className="justify-content-center">
          <Button
            variant="contained"
            color="primary"
            classes={classes}
            startIcon={<FbMessengerIcon />}
            onClick={goToMessenger}>
            Facebook messenger
          </Button>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: '2vh', marginBottom: '2vh' }}>
          <Button
            variant="contained"
            color="primary"
            classes={classes}
            startIcon={<PhoneIcon />}
            onClick={() => window.open('tel:+48606833305', '_blank')}>
            +48 606 833 305
          </Button>
        </Row>
        <Row className="justify-content-center">
          <Button
            variant="contained"
            color="primary"
            classes={classes}
            startIcon={<EMailIcon />}
            onClick={() => window.open('mailto:fashion2u@gmail.com', '_blank')}>
            fashion2u@gmail.com
          </Button>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: '3vh' }}>
          <Col md={'auto'} className=" d-flex justify-content-center">
            <Link to={pages.home.uri} style={{ textDecoration: 'none' }}>
              <BackButton>{t('back')}</BackButton>
            </Link>
          </Col>
        </Row>
      </MainContainer>
    </CSSTransition>
  );
};

export default ContactBody;
