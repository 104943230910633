import devices from './devices.json'


export const device = {
  mobileS: `(min-width: ${devices.mobileS})`,
  mobileM: `(min-width: ${devices.mobileM})`,
  mobileL: `(min-width: ${devices.mobileL})`,
  tablet: `(min-width: ${devices.tablet})`,
  laptop: `(min-width: ${devices.laptop})`,
  laptopL: `(min-width: ${devices.laptopL})`,
  desktop: `(min-width: ${devices.desktop})`,
  desktopL: `(min-width: ${devices.desktop})`
};