import styled from "styled-components"

export const MainContainer = styled.div`
background-color: rgba(255,255,255,0.7);
padding: 1rem;
border-radius: 1rem;
border-width: 2px;
border-style: solid;
border-color: rgba(255,95,79,1);
width: min-content;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
`

