import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMenu } from '../../redux/actions/actions';

const BurgerLine = styled.span`
  width: 60px;
  height: 0.5rem;
  background-color: white;
  border-radius: 1rem;
`;

const BurgerLineOdd = styled(BurgerLine)`
  transform-origin: left;
  ${(props) =>
    props.open &&
    css`
      background-color: rgb(1, 86, 127);
    `}
`;

const BurgerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 500;
`;

const MenuButton = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.isMenuButtonOpen);

  return (
    <CSSTransition in={open} timeout={750} classNames="burger" appear>
      <BurgerWrapper onClick={() => dispatch(toggleMenu())} role={'button'}>
        <BurgerLineOdd open={open} />
        <BurgerLine />
        <BurgerLineOdd open={open} />
      </BurgerWrapper>
    </CSSTransition>
  );
};

export default MenuButton;
