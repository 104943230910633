import React from "react"
import styled from 'styled-components';
import MenuButton from "./misc/MenuButton"

const Wrapper = styled.header`
  margin-top: -3rem;
  height: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const MenuButtonWrapper = styled.div`
  height: 50px;
  width: 60px;
  margin: 4rem 1rem 1rem 1rem; /* + abs(margin-top of header) */
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = () => (
  <Wrapper>
    <MenuButtonWrapper>
      <MenuButton/>
    </MenuButtonWrapper>
  </Wrapper>
)

export default Header
