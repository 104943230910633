import React from 'react';
import styled from 'styled-components';
import { FaFacebookMessenger } from 'react-icons/fa/index';
import { device } from '../../resources/devices';
import { goToMessenger } from '../page/Contact';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  height: 4rem;
  width: 4rem;
  border-radius: 4rem;
  background-color: #0078ff;
  position: absolute;
  left: 5%;
  @media screen and ${device.mobileS} {
    top: 82%;
  }
  @media screen and ${device.mobileM} {
    top: 85%;
  }
  @media screen and ${device.desktop} {
    left: 2%;
  }
  align-items: center;
  justify-content: center;
  &:hover {
    filter: brightness(0.75);
  }
`;

const MessengerButton = () => {
  const { t } = useTranslation('common');

  return (
    <Tooltip title={t('send_msg')} PopperProps={{ disablePortal: true }} arrow placement={'right'}>
      <Wrapper role={'button'} onClick={goToMessenger}>
        <FaFacebookMessenger style={{ color: 'white', height: '50%', width: '50%' }} />
      </Wrapper>
    </Tooltip>
  );
};

export default MessengerButton;
