import React, { Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../resources/css/style.css';
import PropTypes from 'prop-types';
import Footer from './footer';
import styled from 'styled-components';
import Header from './header';
import BurgerMenu from './misc/Menu';
import MessengerButton from './misc/MessengerButton';

const Wrapper = styled.div`
  padding: 3rem 0 2rem 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Suspense fallback={null}>
        <main style={{ minHeight: '100%', display: 'flex', height: '100%' }}>
          {children}
          <MessengerButton />
          <BurgerMenu />
        </main>
      </Suspense>
      <Suspense fallback={null}>
        <Footer />
      </Suspense>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
