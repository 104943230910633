import React from 'react';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { device } from '../resources/devices';
import { FbIcon } from './icons/Icons';

/*color: rgb(194,229,225);*/
const FooterCol = styled(Col)`
  @media screen and ${device.tablet} {
    font-size: 1rem;
  }
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
  text-shadow: 0px 0px 0.15rem rgba(255, 255, 255, 1);
  width: fit-content;
`;

const FooterColLink = styled(FooterCol)`
  cursor: pointer;
`;

const LinkText = styled.span`
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 1s ease-out;
  &:hover {
    border-bottom: 2px solid white;
  }
`;

const Foot = styled.footer`
  height: 2rem;
  width: 100%;
  background-color: rgba(1, 86, 127, 0.9);
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const handleIconClick = () => {
  window.open('https://www.facebook.com/Fashion-2U-107657807368147', '_blank');
};

const Footer = () => {
  const { t } = useTranslation('common');
  return (
    <Foot>
      <FooterCol md={'auto'}>© {new Date().getFullYear()} Fashion2U</FooterCol>
      <FooterColLink md={'auto'} onClick={handleIconClick} role={'button'}>
        <LinkText>{t('find_us_on_fb')}</LinkText>
        <FbIcon />
      </FooterColLink>
    </Foot>
  );
};

export default Footer;
