import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { device } from '../../resources/devices';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import pages from '../../resources/pages.json';

const MainContainer = styled.div`
  @media screen and ${device.tablet} {
    top: -20vw;
    right: -50vw;
  }
  @media screen and ${device.desktop} {
    width: 80vw;
    height: 60rem;
    border-radius: 60rem 0 0 60rem;
  }
  position: fixed;
  top: -50vw;
  right: -5vw;
  width: 100vw;
  z-index: 20;
  flex-direction: row;
  display: flex;
  background: linear-gradient(45deg, #f85047 30%, #ff8e53 90%);
  height: 40rem;
  border-radius: 40rem 0 0 40rem;
  transform-origin: top right;
`;

const Wrapper = styled.div`
  display: flex;
  height: fit-content;
  width: fit-content;
  color: white;
  font-weight: bold;
  cursor: pointer;
  @media screen and ${device.mobileM} {
    font-size: 4rem;
  }
  @media screen and ${device.laptop} {
    font-size: 5rem;
  }
  @media screen and ${device.desktop} {
    font-size: 6rem;
  }
  font-size: 3.5rem;
  &:hover {
    color: rgb(1, 86, 127);
    transition: color 0.2s ease-in-out;
  }
  &:focus {
    color: rgb(1, 86, 127);
    transition: color 0.2s ease-in-out;
  }
`;

const HomeLink = styled(Wrapper)`
  position: absolute;
  @media screen and ${device.tablet} {
    top: 20%;
    left: 15%;
  }
  @media screen and ${device.desktop} {
    top: 40%;
    left: 5%;
    font-size: 5rem;
  }
  top: 33%;
  left: 10%;
`;

const ProductsLink = styled(Wrapper)`
  position: absolute;
  @media screen and ${device.tablet} {
    top: 37%;
    left: 3%;
  }
  @media screen and ${device.desktop} {
    top: 55%;
    left: 5%;
  }
  top: 48%;
  left: 10%;
`;

const ContactLink = styled(Wrapper)`
  position: absolute;
  @media screen and ${device.tablet} {
    top: 55%;
    left: 15%;
  }
  @media screen and ${device.desktop} {
    top: 70%;
    left: 10%;
  }
  top: 63%;
  left: 20%;
`;

const BurgerMenu = () => {
  const { t } = useTranslation('common');
  const open = useSelector((state) => state.isMenuButtonOpen);

  return (
    <CSSTransition in={open} timeout={750} classNames="bmenu" unmountOnExit>
      <MainContainer>
        <HomeLink role={'navigation'} onClick={() => navigate(pages.home.uri)}>
          {t('home')}
        </HomeLink>
        <ProductsLink role={'navigation'} onClick={() => navigate(pages.products.uri)}>
          {t('products')}
        </ProductsLink>
        <ContactLink role={'navigation'} onClick={() => navigate(pages.contact.uri)}>
          {t('contact')}
        </ContactLink>
      </MainContainer>
    </CSSTransition>
  );
};

export default BurgerMenu;
