import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import MailIcon from '@material-ui/icons/Mail';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DetailsIcon from '@material-ui/icons/Details';
import { styled } from '@material-ui/styles';
import { FaFacebookMessenger } from "react-icons/fa/index";
import CloseIcon from '@material-ui/icons/Close';

import React from "react"

export const FbIcon = styled(FacebookIcon)({color:'white' ,height:'1.5rem', width:'1.5rem'});
export const PhoneIcon = styled(PhoneRoundedIcon)({color:'rgba(255,95,79,1)' ,height:'3rem', width:'3rem'});
export const EMailIcon = styled(MailIcon)({color:'rgba(255,95,79,1)' ,height:'3rem', width:'3rem'});
export const FbMessengerIcon = () => <FaFacebookMessenger style={{color:'#0078ff' ,height:'3rem', width:'3rem'}}/>
export const CartIcon = styled(ShoppingCartIcon)({height:'1.5rem', width:'1.5rem'})
export const MuiDetailsIcon = styled(DetailsIcon)({height:'1.5rem', width:'1.5rem'})
export const MuiCloseIcon = styled(CloseIcon)({height:'3rem', width:'3rem'})
